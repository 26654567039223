<template>
    <v-container>
        <div v-if="mode && !['edit', 'create'].includes(mode)" class="edit-button-wrapper">
            <v-btn class="mx-2" dark medium color="#004cff" @click="onEdit">
                <v-icon dark>mdi-account-edit</v-icon>
            </v-btn>
        </div>
        <v-checkbox
            v-model="offer.active"
            label="Active"
        />
        <v-checkbox
            v-model="offer.secret"
            label="Secret"
        />
        <div class="description-editor">
            <v-text-field
                v-model="offer.description"
                label="Description"
                :clearable="clearable"
            />
        </div>
        <div class="description-editor">
            <v-text-field
                v-model="offer.type"
                label="Type"
                :clearable="clearable"
            />
        </div>
        <div class="description-editor">
            <v-text-field
                v-model="offer.typeEn"
                label="Type in English"
                :clearable="clearable"
            />
        </div>
        <v-form :readonly="readOnly">
            <v-autocomplete
                v-model="offer.partner"
                :rules="rules"
                :items="partners"
                :item-text="getFieldText"
                menu-props="auto"
                label="Select Partner"
                hide-details
                prepend-icon="mdi-domain"
                single-line
                :clearable="!readOnly"
                return-object
                deletable-chips
                chips
            />
            <v-menu
                :close-on-content-click="true"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="offer.expirationDate"
                        label="Choose your expiration date"
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        :readonly="readOnly"
                        v-on="on"
                    />
                </template>
                <v-date-picker
                    v-model="offer.expirationDate"
                    :model-config="modelConfig"
                    class="datepicker"
                    :readonly="readOnly"
                />
            </v-menu>
        </v-form>
        <div v-if="mode !== 'show'" class="submit-button-wrapper">
            <v-btn class="my-2 white--text" large color="#004cff" :disabled="shouldDisableSubmit()" @click="onSubmit">
                Submit
            </v-btn>
        </div>
    </v-container>
</template>

<script>
    import moment from 'moment'
    import offerService from '@/services/offer-service'
    import partnerService from '@/services/partner-service'

    export default {
        name: 'OfferForm',
        data: () => {
            return {
                mode: null,
                readOnly: false,
                clearable: true,
                offerId: null,
                offer: {
                    description: '',
                    active: false,
                    expirationDate: '',
                    partner: '',
                    secret: false,
                    type: '',
                    typeEn: ''
                },
                partners: [],
                modelConfig: {
                    type: 'String',
                    mask: 'yyyy-MM-DD'
                },
                rules: [
                    v => !!v || 'Required Field'
                ]
            }
        },
        created() {
            this.mode = this.$route.params.mode
            this.getAllPartners()
            if (this.mode === 'create') {
                return
            }
            if (this.mode === 'show') {
                this.readOnly = true
                this.clearable = false
                this.expirationDateRules = []
            }
            this.offerId = this.$route.params.offerId
            if (this.offerId) {
                offerService.getOfferById(this.offerId).then(resp => {
                    this.offer = resp.data.offer
                    this.offer.expirationDate = this.formatDate(this.offer.expirationDate, 'yyyy-MM-DD')
                })
            }
        },
        methods: {
            shouldDisableSubmit() {
                return !this.offer.partner
            },
            getFieldText(partner) {
                return `${partner.title}`
            },
            onEdit() {
                this.$router.push(`/offers/${this.offerId}/edit?page=${this.$route.query.page}`)
            },
            onSubmit() {
                this.prepareOfferFields()
                if (this.mode === 'edit') {
                    offerService.editOfferById(this.offerId, this.offer).then(() => {
                        this.$router.push(`/offers?page=${this.$route.query.page}`)
                    })
                } else if (this.mode === 'create') {
                    offerService.createOffer(this.offer).then(() => {
                        this.$router.push(`/offers?page=${this.$route.query.page}`)
                    })
                }
            },
            getAllPartners() {
                if (this.mode !== 'show') {
                    partnerService.getAllPartners().then((res) => {
                        this.partners = res.data.data
                    })
                }
            },
            prepareOfferFields() {
                if (this.offer.id) {
                    delete this.offer.id
                }
                if (this.offer.partner) {
                    this.offer.partner = this.offer.partner.id
                }
            },
            formatDate(date, format) {
                return moment(date).format(format)
            }
        }
    }
</script>

<style>
.tox .tox-editor-header {
    z-index: 0 !important;
}
.tox .tox-statusbar {
    display: none !important;
}

.tox .tox-toolbar--scrolling {
    flex-wrap: wrap !important;
}
.tox.tox-tinymce.tox-platform-touch {
    height: 40vh !important;
    min-height: 350px;
}
</style>
<style scoped>
.description-label {
    color: rgba(0, 0, 0, 0.6);
}
.description-editor {
    margin-bottom: 2vh;
}
.edit-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.submit-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 2vh;
}

</style>
